import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#FFFFFF',
      neutralLight1: '#FDFBF4',
      neutralLight2: '#F9F3DD',
      neutralLight3: '#E6E3D9',
      neutralLight4: '#FAE38F',
      primaryDark: '#1A1919',
      neutralDark1: '#504F4E',
      danger: '#ED0F36',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Theano Didot', serif",
    paragraph: "'Hanken Grotesk', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        <MediaContextProvider>{children}</MediaContextProvider>
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
